// import '../../config/node_modules/regenerator-runtime/runtime';
// import './scripts/scrollContainer';
// import './scripts/functions';
// import './scripts/lazyload';
// import './scripts/updatecss';
// import './styles/style';

import('./scripts/scrollContainer');
// import('./scripts/scrollContainer_test');
// import('./scripts/lazyload');
import './styles/style';